require('../app/dist/styles/bundle.min.css');

$('.header-toggler').on('click', function (event) {
    event.preventDefault();
    $('.header').toggleClass('nav-open');
});

$(function () {

    const svg = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 597.1 102"><style type="text/css">.dark{fill:#1E1916;}.orange{fill:#F9A428;}.red{fill:#D32929;}</style><path class="dark" d="M255.6 0c-32.9 0-50.9 22.8-50.9 50.9s17.1 50.9 50.9 50.9c33.9 0 50.9-22.8 50.9-50.9S288.5 0 255.6 0zM255.6 81.7c-18.1 0-27.9-13.8-27.9-30.8s9.1-30.8 27.9-30.8 27.9 13.8 27.9 30.8S273.7 81.7 255.6 81.7zM190.5 35.7c0-24.9-13.3-33.7-44-33.7s-44.3 0-44.3 0v97.9h22.9V70.2c5 0 10.9 0 17.9 0 0.6 0 1.1 0 1.7 0l23.2 29.7h26.7l-25.8-33.1C182.9 62.1 190.5 52.3 190.5 35.7zM143.1 50.1c-4.2 0-10.8 0-17.9 0v-28c7.7 0 15 0 19.4 0 13.2 0 23-0.3 23 13.8C167.6 50.1 156.2 50.1 143.1 50.1zM556.6 1.9H538l-40.6 97.9h22.9l7.5-18.3h38.8l7.5 18.3H597L556.6 1.9zM535.5 63.1l11.8-28.9 11.8 28.9H535.5zM368.3 41.2c-18.2-4.4-22.8-6.5-22.8-13 0-5.1 4.7-8.9 13.6-8.9 19.1 0 31.2 6.3 31.2 6.3L399 7.3c0 0-12.9-7.3-39.6-7.3 -21.5 0-36.9 11.9-36.9 29.9 0 19.9 13.7 25.5 35 30.6 17.6 4.3 21.3 7.1 21.3 12.6 0 6.1-5.7 9.6-15.2 9.6 -19.4 0-36.2-8.8-36.2-8.8l-7.8 18.4c0 0 15 9.6 43.5 9.6 22.8 0 38.7-11 38.7-30.7C401.8 53.6 389.7 46.4 368.3 41.2zM501.3 22.1l8.4-20.2h-89.4l-9.5 20.2h36.9v77.8h22.9V22.1H501.3z" /><polygon class="orange" points="79.3 22.1 79.3 1.9 29.6 1.9 25.3 22.1 " /><polygon class="dark" points="48.9 79.7 44.6 99.9 79.3 99.9 79.3 79.7 " /><polygon class="red" points="-0.1 61 79.3 61 79.3 40.8 4.3 40.8 " /></svg>';

    const css = 'background: url("data:image/svg+xml;utf8,' + svg + '") left center no-repeat; font-size: 120px;';

    console.log('%c     ', css);
    console.log('Мы нанимаем лучших, учимся и делимся опытом');
    console.log('Вакансии: https://hh.kz/employer/2247060');

});
